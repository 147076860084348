import './js/sylius';
import './scss/index.scss';
import '../../../vendor/sylius/bootstrap-theme/assets/js/fontawesome';
import './js/vendor.min';
import './js/unishop';

import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';

library.add(faBars);
dom.watch();

const $ = require('jquery');

// main entry point
global.jQuery = $;
global.$ = $;
